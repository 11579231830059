<template>
    <div class="h-full">
        <router-view />
    </div>
</template>

<script>



export default {
    setup() {

        return {};
    },
};
</script>

<style lang="scss"></style>
