<template>
    <div class="h-full w-full">

        <div class="flex flex-wrap flex-1 gap-4 justify-center m-10 p-5 bg-grey rounded-card shadow-md">
            <div class="w-3/12">
                <label for="name">Nome Completo</label>
                <InputText id="name" type="text" v-model="form.name" placeholder="Nome" class="w-full"
                    aria-describedby="name-help" />
            </div>
            <div class="w-3/12">
                <label for="name">Cargo</label>
                <InputText id="name" type="text" v-model="form.cargo" placeholder="Cargo" class="w-full"
                    aria-describedby="name-help" />
            </div>
            <div class="w-3/12">
                <label for="name">Numero</label>
                <InputText id="name" type="text" v-model="form.numero" placeholder="Numero" class="w-full"
                    aria-describedby="name-help" />
            </div>
            <div class="w-3/12">
                <label for="name">Email</label>
                <InputText id="name" type="text" v-model="form.email" placeholder="Email" class="w-full"
                    aria-describedby="name-help" />
            </div>
            <div class="w-full flex justify-center mt-5">
                <Button class="w-3/12" label="Adicionar" @click="add" />
            </div>
        </div>

        <div class=" m-10 w-full">
            <table v-for="(email, index) in emails" :key="index"
                style="font-family: Arial, sans-serif; font-size: 14px; color: #333333;border: none;">
                <tr>
                    <td style="border: none;">
                        <img :src="email.img" alt="Foto" />
                    </td>
                    <td style="padding-left: 10px;border: none;">
                        <p style="font-weight: bold; margin: 0; font-size: 15px;border: none;">
                            {{ email.name }}
                        </p>
                        <p style="margin: 0px; font-size: 12px;border: none;">{{ email.cargo }}</p>
                        <p style="margin: 15px 0px 0px 0px; font-weight: bold; font-size: 12px;border: none;">
                            {{ email.numero }}
                        </p>
                        <p style="margin: 3px 0 0 0; font-size: 12px;border: none;">
                            <a :href="`mailto:${email.email}`" style="margin: 0; text-decoration: none; color: #333333;border: none;">{{
                                email.email }}</a>
                        </p>
                        <p style="margin: 3px 0 0 0; font-size: 12px;border: none;">
                            <a href="https://eludico.com.br"
                                style="margin: 0; text-decoration: none; color: #333333;border: none;">www.eludico.com.br</a>
                        </p>
                    </td>
                    <td style="padding: 0px 0px 0px 20px;border: none;">
                        <img :src="require('../assets/img/Ativo6.png')" alt="Instagram" />
                    </td>
                </tr>
                <tr>
                    <td style="border: none;">
                        <a href="https://eludico.com.br" target="_blank">
                            <img :src="require('../assets/img/eludico22.png')" alt="Eludico logo" /></a>
                    </td>
                    <td style="border: none;">
                        <p style="margin: 0px 0px 0px 20px;border: none;">
                            <a style="margin: 0px 5px;display: inline-block;" href="https://www.facebook.com/eludicosg/"
                                target="_blank"><img :src="require('../assets/img/Fb@2x.png')" alt="Facebook" /></a>
                            <a style="margin: 0px 5px;display: inline-block;"
                                href="https://www.linkedin.com/company/eludico/" target="_blank"><img
                                    :src="require('../assets/img/In@2x.png')" alt="Linkedin" /></a>
                            <a style="margin: 0px 5px;display: inline-block;" href="https://www.instagram.com/eludico_/"
                                target="_blank"><img :src="require('../assets/img/Ig@2x.png')" alt="Instagram" /></a>
                        </p>
                    </td>

                </tr>
            </table>
        </div>
    </div>
</template>

<script setup>
import { reactive, ref } from 'vue';

const form = reactive({
    name: '',
    cargo: "",
    numero: '',
    email: '',
    img: require('../assets/img/Ativo5.png')
});

const emails = ref([
    {
        name: 'Rafa Sampaio',
        cargo: "Sócio | Diretor de design",
        numero: '(11) 99107.3806',
        email: 'rafael.sampaio@eludico.com.br',
        img: require('../assets/img/Ativo3.png')
    },
    {
        name: 'Kaique Tavares',
        cargo: "Sócio | Gerente de Produtos",
        numero: '(11) 99782.1999',
        email: 'kaique.tavares@eludico.com.br',
        img: require('../assets/img/Ativo4.png')
    },
    {
        name: 'Daniel Tezolin',
        cargo: "Sócio | Gerente de Desenvolvimento",
        numero: '(11) 95596.5222',
        email: 'daniel.tezolin@eludico.com.br',
        img: require('../assets/img/Ativo5.png')
    },
    {
        name: 'Fernanda Franco',
        cargo: "Business Development Representative",
        numero: '(31) 9921-8733',
        email: 'fernanda.franco@eludico.com.br',
        img: require('../assets/img/kdj48dl.png')
    }
])


function add() {
    emails.value.push(form);
}

</script>

<style lang="scss">
.nav-responsive {
    padding: 0 0 0 200px;
    height: 100%;

    &.mobile-active {
        padding: 0 0 0 0;
    }
}
</style>
