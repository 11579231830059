import { createRouter, createWebHistory } from 'vue-router';
import aplication from '@/views/Aplication.vue';


const routes = [
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: aplication,
    },
    {
        path: '/app',
        name: 'aplication',
        component: aplication,
    },

];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        return { top: 0 };
    },
});



export default router;
